import { Injectable } from '@angular/core';
import { CommonService } from '../common.service';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class PytCustTblService {
  // issort = Default = true; false;   add this if hide from perticular col
  // isDate: ''; isDate: false; isDate: true; isDate: 'MMM d, y';
  // Default isnumeric: false; isnumeric: true;

  constructor(private _CommonService: CommonService,private datePipe:DatePipe) { }

  dtGetCellValue(row: any, column: any) {

    let ds = column.name.split('.').reduce((prev: any, curr: string) => {
      return prev[curr];
    }, row);
    if (column.isDate) {
      return this._CommonService.getTransformDate(ds, column.isDate);
    }
    // ds=this.datePipe?.transform(ds, 'yyyy-MM-dd');

    return ds;
  }
  dtSortHeaderClick(item: any, diTableOptions: any) {

    if (diTableOptions.config.sortBy === item.name) {
      diTableOptions.config.sortDirection = diTableOptions.config.sortDirection === 'asc' ? 'desc' : 'asc';
    }
    diTableOptions.config.sortBy = item.name;
    var column: any = diTableOptions.columns.filter((column: any) => column.name === diTableOptions.config.sortBy)[0];
    var isNumeric: boolean = column.isnumeric ? true : false;
    if (diTableOptions.dtUpdateFnCallBack && typeof diTableOptions.dtUpdateFnCallBack == 'function') {
      diTableOptions.dtUpdateFnCallBack();
    }
  }

  dtIsSort(item: any, dtTableOptions: any) {
    return (item.issort || item.issort == undefined) && dtTableOptions.config.sortBy !== item.name && item.name !== '';
  }

  dtIsSortAsc(item: any, diTableOptions: any) {
    var isSortAsc: boolean = (item.issort || item.issort == undefined) && diTableOptions.config.sortBy === item.name && diTableOptions.config.sortDirection === 'asc';
    return isSortAsc;
  }

  dtIsSortDesc(item: any, diTableOptions: any) {
    var isSortDesc: boolean = (item.issort || item.issort == undefined) && diTableOptions.config.sortBy === item.name && diTableOptions.config.sortDirection === 'desc';
    return isSortDesc;
  }

  dtSearchBy(val: any, diTableOptions: any) {
    let input = val.target.value.toUpperCase();
    diTableOptions.config.search = input;
    diTableOptions.config.itmeLengthStartFrom = 0;
    diTableOptions.config.currentPage = 1;
    if (diTableOptions.dtUpdateFnCallBack && typeof diTableOptions.dtUpdateFnCallBack == 'function') {
      diTableOptions.dtUpdateFnCallBack();
    }
  }
  dtUpdateByPageLength(item: any, diTableOptions: any) {
    diTableOptions.config.totalItmePerPage = item.target.value;
    diTableOptions.config.itmeLengthStartFrom = 0;
    diTableOptions.config.currentPage = 1;
    if (diTableOptions.dtUpdateFnCallBack && typeof diTableOptions.dtUpdateFnCallBack == 'function') {
      diTableOptions.dtUpdateFnCallBack();
    }
  }

  dtUpdateByPagination(val: any, diTableOptions: any,) {
    if (val == 'back') {
      diTableOptions.config.currentPage = diTableOptions.config.currentPage - 1;
    } else if (val == 'next') {
      diTableOptions.config.currentPage = diTableOptions.config.currentPage + 1;
    } else if (val == 'first') {
      diTableOptions.config.currentPage = 1;
    } else if (val == 'last') {
      diTableOptions.config.currentPage = diTableOptions.config.totalPages;
    } else if (typeof val == 'number') {
      diTableOptions.config.currentPage = val;
    }

    diTableOptions.config.itmeLengthStartFrom = (diTableOptions.config.currentPage - 1) * diTableOptions.config.totalItmePerPage;
    if (diTableOptions.dtUpdateFnCallBack && typeof diTableOptions.dtUpdateFnCallBack == 'function') {
      diTableOptions.dtUpdateFnCallBack();
    };
  }
}
