<nav id="sidebar">
  <div class="profile-info">
    <figure></figure>
    <div class="user-info">
      <img src="assets/assets/img/90x90.jpg" alt="avatar" />
      <h6 class="">{{ userInfo.name }}</h6>
      <p class="">{{ userInfo.type_name }}</p>
    </div>
  </div>
  <div class="shadow-bottom"></div>
  <ul class="list-unstyled menu-categories" id="accordionExample">
    <li class="menu">
      <a
        routerLink="/dashboard"
        class="dropdown-toggle"
        routerLinkActive="active-link"
      >
        <div class="">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-home"
          >
            <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
            <polyline points="9 22 9 12 15 12 15 22"></polyline>
          </svg>
          <span>Dashboard</span>
        </div>
      </a>
    </li>

    <li
      *ngIf="this.userInfo.type == 'A'"
      class="menu"
      [is-permit]="['category.read']"
    >
      <a
        href="#itemmaster"
        data-toggle="collapse"
        class="dropdown-toggle dt"
        id="itemMaster1"
        aria-expanded="false"
      >
        <div class="">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-archive"
          >
            <polyline points="21 8 21 21 3 21 3 8"></polyline>
            <rect x="1" y="3" width="22" height="5"></rect>
            <line x1="10" y1="12" x2="14" y2="12"></line>
          </svg>
          <span> Item Master</span>
        </div>
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-chevron-right"
          >
            <polyline points="9 18 15 12 9 6"></polyline>
          </svg>
        </div>
      </a>
      <ul
        class="collapse submenu recent-submenu mini-recent-submenu list-unstyled"
        id="itemmaster"
      >
        <li>
          <a routerLink="/item/master/create"> Create </a>
        </li>
        <li>
          <a routerLink="/item/master/"> Listing </a>
        </li>
        <li>
          <a routerLink="/item/master/upload"> Upload Item Master </a>
        </li>
        <li>
          <a routerLink="/item/master/unmapped-item"> Unmapped Item </a>
        </li>
      </ul>
    </li>

    <li
      *ngIf="this.userInfo.type == 'A'"
      class="menu"
      [is-permit]="['company.read']"
    >
      <a
        href="#company-master"
        data-toggle="collapse"
        aria-expanded="false"
        class="dropdown-toggle dt"
        id="companyMaster1"
        role="button"
      >
        <div class="">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-briefcase"
          >
            <rect x="2" y="7" width="20" height="14" rx="2" ry="2"></rect>
            <path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path>
            <polyline points="21 8 21 21 3 21 3 8"></polyline>
            <rect x="1" y="3" width="22" height="5"></rect>
            <line x1="10" y1="12" x2="14" y2="12"></line>
          </svg>
          <span> Company Master</span>
        </div>
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-chevron-right"
          >
            <polyline points="9 18 15 12 9 6"></polyline>
          </svg>
        </div>
      </a>
      <ul
        class="collapse submenu recent-submenu mini-recent-submenu list-unstyled"
        id="company-master"
      >
        <li>
          <a routerLink="/company-master/company/create"> Create </a>
        </li>
        <li>
          <a routerLink="/company-master/company"> Listing </a>
        </li>
      </ul>
    </li>

    <li *ngIf="this.userInfo.type == 'A'" class="menu">
      <a
        href="#categories"
        data-toggle="collapse"
        aria-expanded="false"
        class="dropdown-toggle dt"
        id="categories1"
      >
        <div class="">
          <svg
            _ngcontent-lip-c21=""
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-user"
          >
            <path
              _ngcontent-lip-c21=""
              d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"
            ></path>
            <circle _ngcontent-lip-c21="" cx="12" cy="7" r="4"></circle>
          </svg>
          <span>Categories</span>
        </div>
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-chevron-right"
          >
            <polyline points="9 18 15 12 9 6"></polyline>
          </svg>
        </div>
      </a>
      <ul
        class="collapse submenu list-unstyled"
        id="categories"
        data-parent="#accordionExample"
      >
        <li>
          <a
            href="#brand"
            data-toggle="collapse"
            aria-expanded="false"
            class="dropdown-toggle"
          >
            Brand Master
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-chevron-right"
            >
              <polyline points="9 18 15 12 9 6"></polyline>
            </svg>
          </a>
          <ul
            class="collapse list-unstyled sub-submenu"
            id="brand"
            data-parent="#categories"
          >
            <li>
              <a routerLink="/categories/brand-master/create">Create</a>
            </li>
            <li>
              <a routerLink="/categories/brand-master/"> Listing</a>
            </li>
          </ul>
        </li>
        <li>
          <a
            href="#business"
            data-toggle="collapse"
            aria-expanded="false"
            class="dropdown-toggle"
          >
            Business Unit
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-chevron-right"
            >
              <polyline points="9 18 15 12 9 6"></polyline>
            </svg>
          </a>
          <ul
            class="collapse list-unstyled sub-submenu"
            id="business"
            data-parent="#categories"
          >
            <li>
              <a routerLink="/categories/business-unit/create"> Create</a>
            </li>
            <li>
              <a routerLink="/categories/business-unit/">Listing</a>
            </li>
          </ul>
        </li>
        <li>
          <a
            href="#color"
            data-toggle="collapse"
            aria-expanded="false"
            class="dropdown-toggle"
          >
            Color Master
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-chevron-right"
            >
              <polyline points="9 18 15 12 9 6"></polyline>
            </svg>
          </a>
          <ul
            class="collapse list-unstyled sub-submenu"
            id="color"
            data-parent="#categories"
          >
            <li>
              <a routerLink="/categories/color/Create">Create </a>
            </li>
            <li>
              <a routerLink="/categories/color/">Listing </a>
            </li>
          </ul>
        </li>
        <li>
          <a
            href="#drug"
            data-toggle="collapse"
            aria-expanded="false"
            class="dropdown-toggle"
          >
            Drug Categories
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-chevron-right"
            >
              <polyline points="9 18 15 12 9 6"></polyline>
            </svg>
          </a>
          <ul
            class="collapse list-unstyled sub-submenu"
            id="drug"
            data-parent="#categories"
          >
            <li>
              <a routerLink="/categories/drug-categories/create">Create</a>
            </li>
            <li>
              <a routerLink="/categories/drug-categories/">Listing</a>
            </li>
          </ul>
        </li>
        <li>
          <a
            href="#form"
            data-toggle="collapse"
            aria-expanded="false"
            class="dropdown-toggle"
          >
            Form Master<svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-chevron-right"
            >
              <polyline points="9 18 15 12 9 6"></polyline>
            </svg>
          </a>
          <ul
            class="collapse list-unstyled sub-submenu"
            id="form"
            data-parent="#categories"
          >
            <li>
              <a routerLink="/categories/form-master/create"> Create</a>
            </li>
            <li>
              <a routerLink="/categories/form-master/">Listing</a>
            </li>
          </ul>
        </li>
        <li>
          <a
            href="#pack"
            data-toggle="collapse"
            aria-expanded="false"
            class="dropdown-toggle"
          >
            Pack Master
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-chevron-right"
            >
              <polyline points="9 18 15 12 9 6"></polyline>
            </svg>
          </a>
          <ul
            class="collapse list-unstyled sub-submenu"
            id="pack"
            data-parent="#categories"
          >
            <li>
              <a routerLink="/categories/pack-master/create"> Create</a>
            </li>
            <li>
              <a routerLink="/categories/pack-master/"> Listing</a>
            </li>
          </ul>
        </li>
        <li>
          <a
            href="#salt"
            data-toggle="collapse"
            aria-expanded="false"
            class="dropdown-toggle"
          >
            Salt Master
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-chevron-right"
            >
              <polyline points="9 18 15 12 9 6"></polyline>
            </svg>
          </a>
          <ul
            class="collapse list-unstyled sub-submenu"
            id="salt"
            data-parent="#categories"
          >
            <li>
              <a routerLink="/categories/salt-master/create"> Create</a>
            </li>
            <li>
              <a routerLink="/categories/salt-master/">Listing</a>
            </li>
          </ul>
        </li>
        <li>
          <a
            href="#saltvarient"
            data-toggle="collapse"
            aria-expanded="false"
            class="dropdown-toggle"
          >
            Salt Varient
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-chevron-right"
            >
              <polyline points="9 18 15 12 9 6"></polyline>
            </svg>
          </a>
          <ul
            class="collapse list-unstyled sub-submenu"
            id="saltvarient"
            data-parent="#categories"
          >
            <li>
              <a routerLink="/categories/salt-varient/Create"> Create</a>
            </li>
            <li>
              <a routerLink="/categories/salt-varient/">Listing</a>
            </li>
          </ul>
        </li>
        <li>
          <a
            href="#therapeutic"
            data-toggle="collapse"
            aria-expanded="false"
            class="dropdown-toggle"
          >
            Therapeutic Master
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-chevron-right"
            >
              <polyline points="9 18 15 12 9 6"></polyline>
            </svg>
          </a>
          <ul
            class="collapse list-unstyled sub-submenu"
            id="therapeutic"
            data-parent="#categories"
          >
            <li>
              <a routerLink="/categories/therapeutic-Usage-Master/create"
                >Create
              </a>
            </li>
            <li>
              <a routerLink="/categories/therapeutic-Usage-Master/">Listing </a>
            </li>
          </ul>
        </li>
        <li>
          <a
            href="#tags"
            data-toggle="collapse"
            aria-expanded="false"
            class="dropdown-toggle"
          >
            Tags Master
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-chevron-right"
            >
              <polyline points="9 18 15 12 9 6"></polyline>
            </svg>
          </a>
          <ul
            class="collapse list-unstyled sub-submenu"
            id="tags"
            data-parent="#categories"
          >
            <li>
              <a routerLink="/categories/tags-master/create">Create </a>
            </li>
            <li>
              <a routerLink="/categories/tags-master/">Listing </a>
            </li>
          </ul>
        </li>
        <li>
          <a
            href="#Unit"
            data-toggle="collapse"
            aria-expanded="false"
            class="dropdown-toggle"
          >
            Unit Master
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-chevron-right"
            >
              <polyline points="9 18 15 12 9 6"></polyline>
            </svg>
          </a>
          <ul
            class="collapse list-unstyled sub-submenu"
            id="Unit"
            data-parent="#categories"
          >
            <li>
              <a routerLink="/categories/unit/Create">Create </a>
            </li>
            <li>
              <a routerLink="/categories/unit/">Listing </a>
            </li>
          </ul>
        </li>
      </ul>
    </li>

    <li
      *ngIf="this.userInfo.type == 'A'"
      class="menu"
      [is-permit]="['user.admin.read', 'user.buyer.read', 'user.seller.read']"
    >
      <a
        href="#usermaster"
        data-toggle="collapse"
        aria-expanded="false"
        class="dropdown-toggle dt"
        id="userMaster1"
      >
        <div class="">
          <svg
            _ngcontent-lip-c21=""
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-user"
          >
            <path
              _ngcontent-lip-c21=""
              d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"
            ></path>
            <circle _ngcontent-lip-c21="" cx="12" cy="7" r="4"></circle>
          </svg>
          <span>User Master</span>
        </div>
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-chevron-right"
          >
            <polyline points="9 18 15 12 9 6"></polyline>
          </svg>
        </div>
      </a>
      <ul
        class="collapse submenu list-unstyled"
        id="usermaster"
        data-parent="#accordionExample"
      >
        <li>
          <a
            href="#admin"
            data-toggle="collapse"
            aria-expanded="false"
            class="dropdown-toggle"
          >
            Admin
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-chevron-right"
            >
              <polyline points="9 18 15 12 9 6"></polyline>
            </svg>
          </a>
          <ul
            class="collapse list-unstyled sub-submenu"
            id="admin"
            data-parent="#usermaster"
          >
            <li is-permit="user.admin.create">
              <a routerLink="/user-management/admin/create"> Create </a>
            </li>
            <li is-permit="user.admin.read">
              <a routerLink="/user-management/admin/"> Listing </a>
            </li>
          </ul>
        </li>
        <li>
          <a
            href="#buyer"
            data-toggle="collapse"
            aria-expanded="false"
            class="dropdown-toggle"
          >
            Buyer
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-chevron-right"
            >
              <polyline points="9 18 15 12 9 6"></polyline>
            </svg>
          </a>
          <ul
            class="collapse list-unstyled sub-submenu"
            id="buyer"
            data-parent="#usermaster"
          >
            <li is-permit="user.buyer.create">
              <a routerLink="/user-management/buyer/under-process/create">
                Create
              </a>
            </li>
            <li is-permit="user.buyer.read">
              <a routerLink="/user-management/buyer/"> Listing </a>
            </li>
            <li is-permit="user.buyer.read">
              <a routerLink="/user-management/buyer/under-process/">
                Under Process
              </a>
            </li>
          </ul>
        </li>
        <li>
          <a
            href="#seller"
            data-toggle="collapse"
            aria-expanded="false"
            class="dropdown-toggle"
          >
            Seller
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-chevron-right"
            >
              <polyline points="9 18 15 12 9 6"></polyline>
            </svg>
          </a>
          <ul
            class="collapse list-unstyled sub-submenu"
            id="seller"
            data-parent="#usermaster"
          >
            <li is-permit="user.seller.create">
              <a routerLink="/user-management/seller/under-process/create">
                Create
              </a>
            </li>
            <li is-permit="user.seller.read">
              <a routerLink="/user-management/seller/"> Listing </a>
            </li>
            <li is-permit="user.seller.read">
              <a routerLink="/user-management/seller/under-process/">
                Under Process
              </a>
            </li>
          </ul>
        </li>
        <li>
          <a routerLink="/user-management/user-not-found"
            >User Not Found
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-chevron-right"
            >
              <polyline points="9 18 15 12 9 6"></polyline>
            </svg>
          </a>
        </li>
      </ul>
    </li>
    <li
      *ngIf="this.userInfo.type == 'A'"
      class="menu"
      [is-permit]="['user.onboard.read']"
    >
      <a
        href="#Onboard"
        data-toggle="collapse"
        class="dropdown-toggle dt"
        id="onboard1"
        aria-expanded="false"
      >
        <div class="">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-user-plus"
          >
            <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
            <circle cx="8.5" cy="7" r="4"></circle>
            <line x1="20" y1="8" x2="20" y2="14"></line>
            <line x1="23" y1="11" x2="17" y2="11"></line>
          </svg>
          <span>User Onboarding</span>
        </div>
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-chevron-right"
          >
            <polyline points="9 18 15 12 9 6"></polyline>
          </svg>
        </div>
      </a>
      <ul
        class="collapse submenu recent-submenu mini-recent-submenu list-unstyled"
        id="Onboard"
      >
        <li is-permit="user.onboard.read">
          <a routerLink="/user-management/onboard/"> Onboard/Self-Signup</a>
        </li>
      </ul>
    </li>

    <li *ngIf="this.userInfo.type == 'A'" class="menu">
      <a
        href="#prefence"
        data-toggle="collapse"
        class="dropdown-toggle dt"
        id="preference1"
        aria-expanded="false"
      >
        <div class="">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-user-plus"
          >
            <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
            <circle cx="8.5" cy="7" r="4"></circle>
            <line x1="20" y1="8" x2="20" y2="14"></line>
            <line x1="23" y1="11" x2="17" y2="11"></line>
          </svg>
          <span>Prefrence User</span>
        </div>
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-chevron-right"
          >
            <polyline points="9 18 15 12 9 6"></polyline>
          </svg>
        </div>
      </a>
      <ul
        class="collapse submenu recent-submenu mini-recent-submenu list-unstyled"
        id="prefence"
      >
        <li>
          <a routerLink="/prefrence/create"> Create</a>
        </li>
        <li>
          <a routerLink="/prefrence/list"> List</a>
        </li>
      </ul>
    </li>

    <li class="menu" *ngIf="this.userInfo.type == 'A'">
      <a
        routerLink="/user-management/area-mapping"
        id="areaMapping1"
        aria-expanded="false"
        class="dropdown-toggle dt"
        routerLinkActive="active-link"
      >
        <div class="">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-home"
          >
            <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
            <polyline points="9 22 9 12 15 12 15 22"></polyline>
          </svg>
          <span> Area Mapping</span>
        </div>
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-chevron-right"
          >
            <polyline points="9 18 15 12 9 6"></polyline>
          </svg>
        </div>
      </a>
    </li>

    <!-- <li *ngIf="this.userInfo.type == 'S'" class="menu" >
            <a href="#placeoder" data-toggle="collapse" class="dropdown-toggle">
                <div class="">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user-plus">
                        <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                        <circle cx="8.5" cy="7" r="4"></circle>
                        <line x1="20" y1="8" x2="20" y2="14"></line>
                        <line x1="23" y1="11" x2="17" y2="11"></line>
                    </svg>
                    <span>Place Order</span>
                </div>
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right">
                        <polyline points="9 18 15 12 9 6"></polyline>
                    </svg>
                </div>
            </a>
            <ul class="collapse submenu recent-submenu mini-recent-submenu list-unstyled " id="placeoder">
                <li >
                    <a routerLink="/place-order/admin/list">Place Order Listing</a>
                </li>
            </ul>
        </li> -->

    <!-- -----------------------------------seller ---------------------------------------------------------->
    <li *ngIf="this.userInfo.type == 'S'" class="menu">
      <a
        href="#selleritemMaster"
        id="sellerItem1"
        data-toggle="collapse"
        class="dropdown-toggle dt"
      >
        <div class="">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-user-plus"
          >
            <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
            <circle cx="8.5" cy="7" r="4"></circle>
            <line x1="20" y1="8" x2="20" y2="14"></line>
            <line x1="23" y1="11" x2="17" y2="11"></line>
          </svg>
          <span>Seller Item </span>
        </div>
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-chevron-right"
          >
            <polyline points="9 18 15 12 9 6"></polyline>
          </svg>
        </div>
      </a>
      <ul
        class="collapse submenu recent-submenu mini-recent-submenu list-unstyled"
        id="selleritemMaster"
      >
        <li>
          <a routerLink="/seller/seller-item-master/create"> Create Item </a>
        </li>
        <li>
          <a routerLink="/seller/seller-item-master/"> List Item </a>
        </li>
        <li>
          <a routerLink="/seller/seller-item-master/upload-item">
            Upload Item
          </a>
        </li>
      </ul>
      <!-- <ul class="collapse submenu recent-submenu mini-recent-submenu list-unstyled " id="selleritemMaster">
             
            </ul> -->
      <!-- <ul class="collapse submenu recent-submenu mini-recent-submenu list-unstyled " id="selleritemMaster">
            
            </ul> -->
    </li>
    <li class="menu" *ngIf="this.userInfo.type == 'S'">
      <a
        href="#mapping"
        data-toggle="collapse"
        class="dropdown-toggle dt"
        id="inventoryMapping1"
      >
        <div class="">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-user-plus"
          >
            <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
            <circle cx="8.5" cy="7" r="4"></circle>
            <line x1="20" y1="8" x2="20" y2="14"></line>
            <line x1="23" y1="11" x2="17" y2="11"></line>
          </svg>
          <span>Inventory Mapping</span>
        </div>
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-chevron-right"
          >
            <polyline points="9 18 15 12 9 6"></polyline>
          </svg>
        </div>
      </a>
      <ul
        class="collapse submenu recent-submenu mini-recent-submenu list-unstyled"
        id="mapping"
      >
        <li>
          <a [routerLink]="['/inventory-mapping/item-inventory']">
            Item Mapping</a
          >
        </li>
        <li *ngIf="this.userInfo.type == 'S'">
          <a routerLink="/inventory-mapping/upload"> Upload Inventory</a>
        </li>
      </ul>
    </li>

    <li class="menu" *ngIf="this.userInfo.type == 'A'">
      <a
        routerLink="/user-management/cms"
        aria-expanded="false"
        class="dropdown-toggle dt"
        routerLinkActive="active-link"
        id="cms1"
      >
        <div class="">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-home"
          >
            <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
            <polyline points="9 22 9 12 15 12 15 22"></polyline>
          </svg>
          <span> CMS</span>
        </div>
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-chevron-right"
          >
            <polyline points="9 18 15 12 9 6"></polyline>
          </svg>
        </div>
      </a>
    </li>

    <li class="menu" *ngIf="this.userInfo.type == 'A'">
      <a
        routerLink="/user-management/offer"
        aria-expanded="false"
        class="dropdown-toggle dt"
        routerLinkActive="active-link"
        id="offers1"
      >
        <div class="">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-home"
          >
            <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
            <polyline points="9 22 9 12 15 12 15 22"></polyline>
          </svg>
          <span>Offers</span>
        </div>
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-chevron-right"
          >
            <polyline points="9 18 15 12 9 6"></polyline>
          </svg>
        </div>
      </a>
    </li>

    <!----------------------------------------------------- buyer---------------------------------------------------- -->

    <!-- <li *ngIf=" this.userInfo.type == 'B'" class="menu">
            <a href="#buyerplaceOrder" data-toggle="collapse" class="dropdown-toggle">
                <div class="">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user-plus">
                        <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                        <circle cx="8.5" cy="7" r="4"></circle>
                        <line x1="20" y1="8" x2="20" y2="14"></line>
                        <line x1="23" y1="11" x2="17" y2="11"></line>
                    </svg>
                    <span>Buyer Place Order</span>
                </div>
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right">
                        <polyline points="9 18 15 12 9 6"></polyline>
                    </svg>
                </div>
            </a>
            <ul class="collapse submenu recent-submenu mini-recent-submenu list-unstyled " id="buyerplaceOrder">
                <li>
                    <a routerLink="/buyer/buyer-place-order/"> Place Order</a>
                </li>
            </ul>
        </li> -->

    <!-- <li *ngIf=" this.userInfo.type == 'B'" class="menu">
            <a href="#buyeritemmaster" data-toggle="collapse" class="dropdown-toggle">
                <div class="">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user-plus">
                        <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                        <circle cx="8.5" cy="7" r="4"></circle>
                        <line x1="20" y1="8" x2="20" y2="14"></line>
                        <line x1="23" y1="11" x2="17" y2="11"></line>
                    </svg>
                    <span>Item Master</span>
                </div>
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right">
                        <polyline points="9 18 15 12 9 6"></polyline>
                    </svg>
                </div>
            </a>
            <ul class="collapse submenu recent-submenu mini-recent-submenu list-unstyled " id="buyeritemmaster">
                <li>
                    <a routerLink="/buyer/item-master/create"> Create Item</a>
                </li>
            </ul>
            <ul class="collapse submenu recent-submenu mini-recent-submenu list-unstyled " id="buyeritemmaster">
                <li>
                    <a routerLink="/buyer/item-master"> List Item</a>
                </li>
            </ul>
            <ul class="collapse submenu recent-submenu mini-recent-submenu list-unstyled " id="buyeritemmaster">
                <li>
                    <a routerLink="/buyer/item-master/upload-item">Upload Buyer Item </a>
                </li>
            </ul>
        </li> -->

    <li
      class="menu"
      *ngIf="this.userInfo.type == 'B' || this.userInfo.type == 'A'"
    >
      <a
        routerLink="/user-management/short-book"
        aria-expanded="false"
        class="dropdown-toggle dt"
        id="shortBook1"
        routerLinkActive="active-link"
      >
        <div class="">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-home"
          >
            <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
            <polyline points="9 22 9 12 15 12 15 22"></polyline>
          </svg>
          <span> Short Book</span>
        </div>
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-chevron-right"
          >
            <polyline points="9 18 15 12 9 6"></polyline>
          </svg>
        </div>
      </a>
    </li>
    <li class="menu">
      <a
        routerLink="/user-management/order-details"
        aria-expanded="false"
        class="dropdown-toggle dt"
        id="orderDetails1"
        routerLinkActive="active-link"
      >
        <div class="">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-home"
          >
            <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
            <polyline points="9 22 9 12 15 12 15 22"></polyline>
          </svg>
          <span> Order Details</span>
        </div>
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-chevron-right"
          >
            <polyline points="9 18 15 12 9 6"></polyline>
          </svg>
        </div>
      </a>
      <a
        *ngIf="sellertype?.data === 'PRIMARY'"
        routerLink="/user-management/Seconday-order-details"
        aria-expanded="false"
        class="dropdown-toggle"
      >
        <div class="">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-home"
          >
            <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
            <polyline points="9 22 9 12 15 12 15 22"></polyline>
          </svg>
          <span>primary to sec Order Details</span>
        </div>
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-chevron-right"
          >
            <polyline points="9 18 15 12 9 6"></polyline>
          </svg>
        </div>
      </a>
    </li>
    <li class="menu">
      <a
        routerLink="/user-management/invoice-details"
        aria-expanded="false"
        class="dropdown-toggle dt"
        id="invoiceDetails1"
        routerLinkActive="active-link"
      >
        <div class="">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-home"
          >
            <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
            <polyline points="9 22 9 12 15 12 15 22"></polyline>
          </svg>
          <span> Invoice Details</span>
        </div>
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-chevron-right"
          >
            <polyline points="9 18 15 12 9 6"></polyline>
          </svg>
        </div>
      </a>
      <a
        *ngIf="sellertype?.data === 'PRIMARY'"
        routerLink="/user-management/Seconday-order-details"
        aria-expanded="false"
        class="dropdown-toggle"
      >
        <div class="">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-home"
          >
            <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
            <polyline points="9 22 9 12 15 12 15 22"></polyline>
          </svg>
          <span>primary to sec Order Details</span>
        </div>
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-chevron-right"
          >
            <polyline points="9 18 15 12 9 6"></polyline>
          </svg>
        </div>
      </a>
    </li>

    <!-- <li class="menu" *ngIf=" this.userInfo.type == 'B'" >
            <a routerLink="/user-management/reorder-from-sell" aria-expanded="false" class="dropdown-toggle">
                <div class="">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-home">
                        <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                        <polyline points="9 22 9 12 15 12 15 22"></polyline>
                    </svg>
                    <span> Reorder from sell</span>
                </div>
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right">
                        <polyline points="9 18 15 12 9 6"></polyline>
                    </svg>
                </div>
            </a>
        </li> -->
  </ul>
</nav>
