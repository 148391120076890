<div class="d-block w-100">
    <ng-autocomplete class="disable" [(ngModel)]="input" [initialValue]="" [data]="data" placeholder="{{placeholder}}" [debounceTime]="debounceTime" [isLoading]="isLoading" [searchKeyword]="keyword" (selected)='selectEvent($event);openModal($event)' [minQueryLength]="1" (inputChanged)='onChangeSearch($event)' 
        (inputCleared)="clearSearch()"  (inputFocused)='onFocused($event)' [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate" [disabled]="isDisabled">
    </ng-autocomplete>

    <ng-template #itemTemplate let-item>
        <a [innerHTML]="item.name"></a>
    </ng-template>

    <ng-template #notFoundTemplate let-notFound>
        <div [innerHTML]="notFound"></div>
    </ng-template>
</div>