import { Router } from '@angular/router';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { catchError, finalize, map, Observable, tap, throwError } from 'rxjs';
import { config } from '../config/config';
import { AuthService } from '../service/auth.service';
import { LoaderService } from '../loader.service';
import Swal from 'sweetalert2';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

  constructor(private auth: AuthService, private loader: LoaderService,private route:Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.loader.show();
    if (request.url.indexOf(`${environment.apiBase}/auth/login`) > -1) {
      request = request.clone({
        setHeaders: {
          'Application-Token': config.APPLICATION_TOKEN
        }
      });
    }else {
      const authToken = this.auth.getAuthToken();
      if (authToken != null) {
        request = request.clone({
          setHeaders: {
            'Application-Token': config.APPLICATION_TOKEN,
            'Authorization': authToken,
          }
        });
      }
    } 


    return next.handle(request).pipe(
      catchError(err => {
         //console.log(err);
        if (err.status === 401){
          this.loader.hide()
          this.auth.logout(); 
          const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
          }) 
          Toast.fire({
            icon: 'warning',
            title: 'Session Expired!!'
          })
        }else if(err.status === 500){
          this.loader.hide()
        }
        const error = err.error.message || err.statusText;
        return throwError(() => error)
      }), 
      map(event => {
        let $next = false;
        if (event instanceof HttpResponse) {   
          this.loader.hide() 
        }  
        return event;
      })
    );
  }
}