import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-bread-crumbs',
  templateUrl: './bread-crumbs.component.html',
  styleUrls: ['./bread-crumbs.component.scss']
})
export class BreadCrumbsComponent implements OnInit {
  
  @Input('title')
  title:string = ''

  @Input('description')
  description:string = ''

  @Input('crumbs')
  crumbs:any = []
  
  constructor() { }

  ngOnInit(): void {
    // console.log(this.crumbs)
  }

}
