import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './service/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {

  constructor(
    private auth: AuthService,
    private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean  {
      var isAuthenticated = this.auth.getAuthStatus();
      console.log(isAuthenticated);
      
      if (!isAuthenticated) {
      console.log('isAuthenticated---->',isAuthenticated);

         this.router.navigate(['/login']);
      }
      // console.log(isAuthenticated);
      return isAuthenticated;
  }
  
}
