import { config } from 'src/app/config/config';
import { ApiService } from 'src/app/service/api.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { StoreService } from 'src/app/service/store.service';
import { GoogleChartInterface, GoogleChartType } from 'ng2-google-charts';
import { AdroitCryptoService } from 'src/app/service/adroit-crypto.service';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  usserType: any;
  dashboardData: any=[];
  // public myGraph = {
  //   chartType: "ColumnChart",
  //   dataTable: [
  //     [{ label: "Year", type: "number" }, "Sales"],
  //     ["1998", 1030],
  //     ["1999", 10000],
  //     ["2000", 1170],
  //     ["2001", 660],
  //     ["2002", 1030],
  //     ["2003", 1030],
  //     ["2004", 1030],
  //     ["2005", 1000],
  //     ["2006", 1170]

  //   ],
  //   options: {
  //     title: "Company Performance",
  //     chartArea: {
  //       width: "90%",
  //       height: "100%"
  //     }
  //   }
  // };
  constructor(private api: ApiService, private store: StoreService, private crypto: AdroitCryptoService) {}

  ngOnInit(): void {
  this.checkUser()
  this.dashboardApi()
  }

  checkUser(){
    this.usserType = this.store.get('userInfo')
    this.api.postData(config.auth.checkSession).subscribe((res: any) => {
      let sessionData = JSON.parse(this.crypto.aesDecrypt(res.resp));
      if (sessionData.code) {
        this.store.set('permission', sessionData.data.permission);
        this.store.set('userInfo', sessionData.data);
      }
      else {
        this.api.error(sessionData);
      }
    })
  }

  dashboardApi(){
    this.api.postData(config.dashboard).subscribe((res:any)=>{
      if(res.statuscode == 200)
      {
        this.dashboardData = res
       }
     else{
       this.api.error(res.message)
     }
    })
  }
}
