import { Directive, HostListener, Input } from "@angular/core";
import { AbstractDebounceDirective } from "./abstract-debounce.directive";
import * as _ from 'lodash';
@Directive({
  selector: '[appSrchDbounce]'
})
export class SrchDbounceDirective extends AbstractDebounceDirective {
  constructor() {
    super();
  }

  @Input()
  public restrictedKeyList: number[] = [];

  @HostListener("keyup", ["$event"])
  public onKeyUp(event: any): void {
    event.preventDefault();
    if (event.keyCode != 16 && event.keyCode != 17 && event.keyCode != 18 && event.keyCode != 9 && this.restrictedKeyList.indexOf(event.keyCode) == -1) {
      this.emitEvent$.next(event);
    }

  }
}
