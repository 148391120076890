import { AfterViewInit, Component, OnInit, Renderer2 } from '@angular/core';
import { AdroitCryptoService } from 'src/app/service/adroit-crypto.service';
import { StoreService } from 'src/app/service/store.service';
// import * as $_ from 'jquery';

declare var App: any;
declare var $: any;

declare var SpatialNavigation: any;
declare function callEnsureVisible(ele: any, SN: any): any;
// declare function asdasd():any;

import * as $_ from 'jquery';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, AfterViewInit {
  show: boolean = false;
  userInfo: any = { name: '', type_name: '' };
  document: any;
  sellertype: any;

  constructor(
    private store: StoreService,
    private renderer: Renderer2,
    private crypto: AdroitCryptoService
  ) {}
  ngOnInit(): void {
    this.userInfo = this.store.get('userInfo');

    setTimeout(() => {
      this.sellertype = JSON.parse(
        this.crypto.aesDecrypt(localStorage.getItem('sellertype'))
      );
    }, 200);
  }

  ngAfterViewInit() {
    setTimeout(() => App.init(), 300);

    $('a.dt').on('click', (e: any) => {
      // e.preventDefault();
      // e.stopPropagation();
      // console.log(e.currentTarget.getAttribute('aria-expanded'), 'click eve');

      // console.log(e.currentTarget.id);

      let elemsArray = document.getElementsByClassName('dt');
      // console.log(elemsArray);

      Array.from(elemsArray).forEach(function (element, idx) {
        if (e.currentTarget.id != element.id) {
          // console.log(element.getAttribute('aria-expanded'), idx);
          element.setAttribute('aria-expanded', 'false');
          element.classList.add('collapsed');
          element.nextElementSibling?.classList.remove('show');
        }
        // console.log(element.id);
      });
    });
  }
}
