import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// import { HeaderComponent } from './common/header/header.component';
// import { FooterComponent } from './common/footer/footer.component';
// import { SidebarComponent } from './common/sidebar/sidebar.component';
import { DashboardComponent } from './common/dashboard/dashboard.component';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpResponse } from '@angular/common/http';
import { HttpRequestInterceptor } from './interceptor/http-request.interceptor';
import { SharedModule } from './shared/shared/shared.module';
import { LoaderComponent } from './common/loader/loader.component';
import { ParentComponent } from './common/parent/parent.component';
import { DatePipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CryptoJsService } from './service/crypto-js.service';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { ToastNotificationsModule } from "ngx-toast-notifications"; 
@NgModule({
  declarations: [
    AppComponent,
    // HeaderComponent,
    // FooterComponent,
    // SidebarComponent,
    DashboardComponent,
    LoaderComponent,
    ParentComponent, 
    
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    Ng2GoogleChartsModule,
    ToastNotificationsModule, 
  ],
  providers: [
    CryptoJsService,
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { 
}
