import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BreadCrumbsComponent } from 'src/app/common/bread-crumbs/bread-crumbs.component';
import { IsPermitDirective } from 'src/app/directives/is-permit.directive';
import { LoaderScreenComponent } from 'src/app/common/loader-screen/loader-screen.component';
import { AbstractDebounceDirective } from 'src/app/directives/debounce/abstract-debounce.directive';
import { SrchDbounceDirective } from 'src/app/directives/debounce/srch-dbounce.directive';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { AutoCompleteModule } from '../auto-complete/auto-complete.module';
import { DataTableToolsModule } from '../data-table-tools/data-table-tools.module';
import { Select2Module } from '../select2/select2.module';
import { FooterComponent } from 'src/app/common/footer/footer.component';
import { HeaderComponent } from 'src/app/common/header/header.component';
import { SidebarComponent } from 'src/app/common/sidebar/sidebar.component';
import { RouterModule } from '@angular/router';
import { PytCustTblPagerDirective } from '../pytCustTbl/pytPagination/pyt-cust-tbl-pager.directive'; 
import { ShowNullResultPipe } from '../show-null-result.pipe';  

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    BreadCrumbsComponent,
    IsPermitDirective,
    PytCustTblPagerDirective,
    LoaderScreenComponent,
    AbstractDebounceDirective,
    SrchDbounceDirective,
    ShowNullResultPipe, 
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    AutoCompleteModule, 
    DataTableToolsModule,
    BsDatepickerModule.forRoot(),
    Select2Module, 
    
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    BreadCrumbsComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    IsPermitDirective,
    LoaderScreenComponent,
    AbstractDebounceDirective,
    PytCustTblPagerDirective,
    SrchDbounceDirective,
    AutoCompleteModule,
    DataTableToolsModule,
    BsDatepickerModule,
    Select2Module,
    ShowNullResultPipe,
    ReactiveFormsModule, 
  ]
})
export class SharedModule { }
