import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { config } from '../config/config';
@Injectable({
  providedIn: 'root'
})
export class AdroitCryptoService {

  constructor() { }

  aesEncrypt(data: any) {
    let key = config.aesEnc.key; //length 32
    let iv = config.aesEnc.iv;
    let cipher = CryptoJS.AES.encrypt(JSON.stringify(data), CryptoJS.enc.Utf8.parse(key), {
      iv: CryptoJS.enc.Utf8.parse(iv),
      mode: CryptoJS.mode.CBC,
    });
    return cipher.toString();
  }
  aesDecrypt(data: any) {
    let key = config.aesEnc.key; //length 32
    let iv = config.aesEnc.iv;
    let cipher = CryptoJS.AES.decrypt(data, CryptoJS.enc.Utf8.parse(key), {
      iv: CryptoJS.enc.Utf8.parse(iv),
      mode: CryptoJS.mode.CBC,
    });
    return cipher.toString(CryptoJS.enc.Utf8);
  }
}
