import { Component, ContentChild, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { ApiService } from 'src/app/service/api.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-data-table-tools',
  templateUrl: './data-table-tools.component.html',
  styleUrls: ['./data-table-tools.component.scss']
})

export class DataTableToolsComponent implements OnInit {

  @ViewChild(DataTableDirective, { static: false })
  dataTableElement!: DataTableDirective;
  @ContentChild('dataTableTemplate') dataTableTemplate!: TemplateRef<any>;

  dtOptions: any = {};
  isShow: boolean = false;
  data: any = [];
  headers: any = [];
  dynamicHeaders: any = [];

  @Input('url')
  url: string = "";

  @Input('headers')
  customHeaders: any = [];
  excelData: any;

  constructor(private api: ApiService) { }

  async ngOnInit() {
    const resp: any = await this.dataTable();
    if (resp.code == 200) {
      this.init(resp.data);
    }
  }

  init(obj: any): void {
    const that = this;
    this.dynamicHeaders = obj.headers;
    this.headers = [...obj.headers, ...this.customHeaders];

    this.dtOptions = {
      pageLength: 10,
      serverSide: true,
      responsive: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback: any) => {
        that.api
          .postToDataTable(
            obj.url,
            dataTablesParameters
          ).subscribe(resp => {
            that.data = resp.data;
            callback({
              draw: resp.draw,
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          });
      },
      columns: that.headers,
      language: {
        search: ""
      },
      dom: '<"row"<"col-md-12"<"row"<"col-md-12"B><"col-md-6"l> <"col-md-6"f> > ><"col-md-12"rt> <"col-md-12"<"row"<"col-md-5"i><"col-md-7"p>>> >',
      buttons: {
        buttons: [
          {
            text: 'Export to csv',
            key: 'csv',
            className:'btn btn-dark mb-3',
            action: function (e:any, dt:any, node:any, config:any) {
              that.download(that.data);
            }
          }
        ]
      
      },
      lengthMenu: [[10, 25, 50, 100, 200, 500, -1], [10, 25, 50, 100, 200, 500, "All"]],
      oLanguage: {
        oPaginate: { "sPrevious": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>', "sNext": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>' },
        sInfo: "Showing page _PAGE_ of _PAGES_",
        sSearch: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>',
        sSearchPlaceholder: "Search...",
        sLengthMenu: "Results :  _MENU_",
      },
      stripeClasses: [],
    };
    this.isShow = true;
  }

  dataTable() {
    return new Promise((resolve, error) => {
      this.api.post(this.url).subscribe(resp => {
        resolve(resp)
      });
    })
  }

  refresh() {
    this.dataTableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });
  }

  getCompiledData() {
    return this.data;
  }
  
  download(data: any){
    console.log('dataaaaaaaaa==>',data);
    let name: any = new Date();
        const fileName = name+'.xlsx';
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Aeps-Statement');
        XLSX.writeFile(wb, fileName);
  }
}
