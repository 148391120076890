import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  active: boolean = true;

  constructor(private loader: LoaderService) {
    this.loader.isLoading.subscribe((isLoading: boolean) => {
      this.active = isLoading;
    });
  }

  ngOnInit(): void {
  }

}
