import { LoaderService } from 'src/app/loader.service';
import { Component, OnInit } from '@angular/core';
import { StoreService } from 'src/app/service/store.service';

@Component({
  selector: 'app-loader-screen',
  templateUrl: './loader-screen.component.html',
  styleUrls: ['./loader-screen.component.scss']
})
export class LoaderScreenComponent implements OnInit {

  constructor( private store: StoreService) { }

  ngOnInit(): void {

  }

}
