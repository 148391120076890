
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';
import { StoreService } from 'src/app/service/store.service';
declare var App :any
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  userInfo: any = { name: "", type_name: "" };
  constructor(private auth: AuthService, private route: Router, private store: StoreService) { }

  ngOnInit(): void {
    this.userInfo = this.store.get('userInfo');
  }
 
  logout(): void {
    this.auth.logout();
    this.route.navigate(['/login']);
  }
  changepassword(){
    this.auth.logout();
    this.route.navigate(['/change-password']);
  }
}
