<div class="row my-4">
    <div class="col-12 d-flex justify-content-between">
        <div class="headings">
            <h5>{{title}}</h5>
            <p>{{description}}</p>
        </div>
        <nav class="breadcrumb-one" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a routerLink="/">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
                    </a>
                </li>
                <li class="breadcrumb-item" *ngFor="let crumb of crumbs" [ngClass]="crumb.active?'active':''"><a href="{{crumb.url}}">{{crumb.label}}</a></li>
            </ol>
        </nav>
    </div>
</div>