import { Injectable } from '@angular/core';
import { CryptoJsService } from './crypto-js.service';
import { config } from '../config/config'
@Injectable({
  providedIn: 'root'
})
export class StoreService {

  constructor(private crypto: CryptoJsService) { }
  has(key: string) {
    const data = localStorage.getItem(key);
    return (data ? true : false);
  }
  set(key: string, value: any) {
    if (typeof value == 'object') {
      value = JSON.stringify(value) 
    }
    // var encryptedData = this.crypto.set(config.secretKey, value);
    // return localStorage.setItem(key, encryptedData);
    return localStorage.setItem(key, value);

  }
  get(key: string) {
    const data = localStorage.getItem(key);
  // if (data) {
    //   var decryptedData = this.crypto.get(config.secretKey, data);
    //   if (decryptedData) {
    //     if (this.isJsonString(decryptedData)) {
    //       return JSON.parse(decryptedData);
    //     }
    //     return decryptedData;
    //   }
    //   return false;
    // }
    if (data) {
        if (this.isJsonString(data)) {
          return JSON.parse(data);
        }
      }
      return false;
  }
  
  remove(key: string) {
    return localStorage.removeItem(key);
  }
  clear() {
    return localStorage.clear();
  }

  isJsonString(str: string) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

}
