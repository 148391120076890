import { Component, OnInit } from '@angular/core';
import { LoaderService } from './loader.service';
import { StoreService } from './service/store.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  storedata: any;

  constructor(private loader: LoaderService, private store: StoreService) {
    this.loader.hide();
   }
  ngOnInit(): void {

  }



}