<!--  BEGIN NAVBAR  -->
<div class="header-container fixed-top">
    <app-header></app-header>
</div>
<!--  END NAVBAR  -->

<!--  BEGIN MAIN CONTAINER  -->
<div class="main-container" id="container">

    <div class="overlay"></div>
    <div class="search-overlay"></div>

    <!--  BEGIN SIDEBAR  -->
    <app-sidebar class="sidebar-wrapper sidebar-theme"></app-sidebar>
    <!--  END SIDEBAR  -->

    <!--  BEGIN CONTENT AREA  -->
    <div id="content" class="main-content">
        <div class="layout-px-spacing">

            <!-- CONTENT AREA -->

            <div class="row layout-top-spacing">
                <div class="col-xl-12 col-lg-12 col-md-12 col-12 layout-spacing">
                    <router-outlet></router-outlet>
                </div>
                <!-- <div class="col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
                    <router-outlet></router-outlet>
                </div> -->
               

            </div>

            <!-- CONTENT AREA -->

        </div>
        <app-footer></app-footer>
    </div>
    <!--  END CONTENT AREA  -->

</div>