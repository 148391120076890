import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { config } from 'src/app/config/config';
import { ApiService } from 'src/app/service/api.service';
import { AuthService } from 'src/app/service/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-select2',
  templateUrl: './select2.component.html',
  styleUrls: ['./select2.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => Select2Component),
      multi: true
    }
  ]
})
export class Select2Component implements ControlValueAccessor {
  options: any;
  selected: any = []
  apiData: any = []

  @Input('data')
  data: any = null;

  @Input('value')
  value: any = [];

  @Input('allowClear')
  allowClear: any = false;

  @Input('disabled ')
  disabled: any = false;

  @Input('url')
  url: any = null;

  @Input('multiple')
  multiple: boolean = false;

  @Input('tags')
  tags: boolean = false;

  @Input('placeholder')
  placeholder: any;

  @Input('apiObject')
  apiObject: any = { q: null };

  input: any;
  headerObj: any;

  constructor(private service: ApiService, private auth: AuthService) { }

  ngOnInit(): void {
    var that = this;
    var optionsObj: any = { width: '100%' };
    optionsObj.multiple = this.multiple
    optionsObj.tags = this.tags
    if (this.tags) {
      optionsObj.createTag = function (params: any) {

        var term = params.term.trim();

        if (term === '') {
          return null;
        }

        return {
          id: term,
          text: term,
          additional: { name: term },
          newTag: true
        }
      };
    }

    if (this.url) {

      const authToken = this.auth.getAuthToken();
      if (authToken != null) {

        this.headerObj = {
          'Application-Token': config.APPLICATION_TOKEN,
          'Authorization': authToken,
        }
      }

      optionsObj.ajax = {
        url: environment.apiBase + this.url,
        headers: this.headerObj,

        type: 'POST',
        delay: 250,
        caches: false,
        dataType: 'json',
        minimumInputLength: 1,
        data: function (params: any) {
          const formData = new FormData();
          formData.append('q', params.term)
          return formData;
        },
        processData: false,
        contentType: false,
        processResults: function (e: any) {
          let value: any = [];
          let i = 1;
          if (e.code == 200) {
            for (var v of e.data) {

              value.push({ id: v.id, text: v.name, additional: v });

            }
          }
          that.apiData = value


          return {
            results: that.apiData

          };
        }
      };
    }
    this.options = optionsObj;
  }

  changed(event: any): void {
    let selectedData = this.apiData.filter((e: any) => (e.selected == true))
    for (let v of selectedData) {
      if (!this.selected.includes(v)) {
        delete v.selected
        this.selected.push(v)
      }
    }
    let actualData = this.selected.filter((e: any) => (event.includes(e.id.toString())));
    actualData = actualData.map((e: any) => e.additional)
    this.value = event;
    this.onChange(actualData)
  }

  onChange: any = () => { }
  onTouch: any = () => { }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(input: any) {
    let value = [];
    let arr = [];
    for (var v of input) {

      value.push({ id: v.id, text: v.name, additional: v });
      arr.push(v.id.toString());
    }
    this.data = value;
    this.value = arr;
  }
}
