<div *ngIf="isShow" class="table-responsive">
    <ng-content></ng-content>

    <table datatable [dtOptions]="dtOptions" class="table style-3 table-hover my-2 ">
        <tbody *ngIf="data?.length != 0">
            <tr *ngFor="let d of data">
                <td *ngFor="let h of dynamicHeaders"> {{d[h.data]||'-'}} </td>
                <ng-container *ngTemplateOutlet="dataTableTemplate; context:{ $implicit: d}"></ng-container>
            </tr> 
        </tbody>
        <tbody *ngIf="data?.length == 0">
            <tr>
                <td colspan="3" class="no-data-available">No data!</td>
            </tr>
        </tbody>
    </table>
</div>