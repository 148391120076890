import { Directive, Input, ElementRef, OnInit } from '@angular/core';
import { AuthService } from '../service/auth.service';

@Directive({
  selector: '[is-permit]'
})
export class IsPermitDirective implements OnInit {

  @Input('is-permit')
  isPermit: any = null;

  constructor(private el: ElementRef, private auth: AuthService) {

  }

  ngOnInit(): void {
    if (typeof this.isPermit == 'object') {
      if (this.auth.getPermissions().find((v: string) => this.isPermit.includes(v)) == undefined ) {
        this.el.nativeElement.remove();
      }
    } else if (typeof this.isPermit == 'string') {
      if (!this.auth.getPermissions().includes(this.isPermit)) {
        this.el.nativeElement.remove();
      }
    }
  }

}
