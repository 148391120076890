<!--  BEGIN CONTENT AREA  -->
<div class="row">
    <div class="col-sm-12 col-lg-3" *ngFor="let item of dashboardData.data ; let i as index">
        <div class="c-delight-main blue">
            <span>{{item?.icon}}</span>
            <div class="text-item">{{item?.count}}</div>
            <h2 class="c-delight">{{item?.title}}</h2>
        </div>

    </div>
   
</div>

<div class="row">

    <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
        <div class="widget widget-table-two">
            <div class="widget-heading">
                <h5 class="">{{dashboardData.latestOrders == 0?'No Previous record':'Previous 5 Orders'}}
                </h5>
            </div>
            <div class="widget-content">
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>
                                    <div class="th-content">Buyer </div>
                                </th>
                                <th>
                                    <div class="th-content">Seller </div>
                                </th>
                                <th>
                                    <div class="th-content">QTY</div>
                                </th>
                                <th>
                                    <div class="th-content">Amount</div>
                                </th>
                                <th>
                                    <div class="th-content th-heading">Status</div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of dashboardData.latestOrders ; let i as index">
                                <td>
                                    <div class="td-content product-brand">{{item?.buyer_id || '--'}}</div>
                                </td>
                                <td>
                                    <div class="td-content product-brand">{{item?.seller_id || '--'}}</div>
                                </td>
                                <td>
                                    <div class="td-content customer-name pimg">{{item?.total_qty || '--'}}</div>
                                </td>
                                <td>
                                    <div class="td-content"><span class="pricing"><i class="fal fa-rupee-sign">
                                                {{item?.total_amount || '--'}}</i></span></div>
                                </td>
                                <td> {{item?.status || '--'}}</td>
                            </tr>


                        </tbody>

                    </table>

                    <div class="tm-action-btn" *ngIf="dashboardData.latestOrders !=0">
                        <button class="btn float-right" routerLink="/user-management/order-details">See All</button>
                    </div>
                </div>
            </div>
            <div *ngIf="dashboardData.latestOrders == 0">
                <span class="d-block text-center">No record Found!</span>
            </div>
        </div>
    </div>

    <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
        <div class="widget widget-table-two">
            <div class="widget-heading">
                <h5 class="">{{dashboardData.shortbook == 0?'No record of ShortBook':'ShortBook Details'}}
                </h5>
            </div>
            <div class="widget-content">
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>
                                    <div class="th-content">Item Id </div>
                                </th>
                                <th>
                                    <div class="th-content">User Id </div>
                                </th>
                                <th>
                                    <div class="th-content">QTY</div>
                                </th>
                                <th>
                                    <div class="th-content">Created At</div>
                                </th>

                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of dashboardData.shortbook ; let i as index">
                                <td>
                                    <div class="td-content product-brand">{{item?.item_id || '--'}}</div>
                                </td>
                                <td>
                                    <div class="td-content product-brand">{{item?.user_id || '--'}}</div>
                                </td>
                                <td>
                                    <div class="td-content customer-name pimg">{{item?.qty || '--'}}</div>
                                </td>
                                <td>
                                    <div class="td-content"><span class="pricing"> {{item?.created_at || '--'}}</span>
                                    </div>
                                </td>

                            </tr>


                        </tbody>

                    </table>

                    <div class="tm-action-btn" *ngIf="dashboardData.shortbook !=0">
                        <button class="btn float-right" routerLink="/user-management/short-book">See All</button>
                    </div>
                </div>
            </div>
            <div *ngIf="dashboardData.shortbook == 0">
                <span class="d-block text-center">No record Found!</span>
            </div>
        </div>
    </div>
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing" >
        <div class="widget widget-table-two">
            <div class="widget-heading">
                <h5 class="">{{dashboardData.unmapped == 0?'No Unmapped Data record':'Unmapped Data List'}}
                </h5>
            </div>
            <div class="widget-content">
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>
                                    <div class="th-content">Item Code </div>
                                </th>
                                <th>
                                    <div class="th-content">Company Name </div>
                                </th>
                                <th>
                                    <div class="th-content">Name</div>
                                </th>
                                <th>
                                    <div class="th-content">MRP</div>
                                </th>
                                <th>
                                    <div class="th-content th-heading">PTR</div>
                                </th>
                                <th>
                                    <div class="th-content th-heading">Created At</div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of dashboardData.unmapped ; let i as index">
                                <td>
                                    <div class="td-content product-brand">{{item?.item_code || '--'}}</div>
                                </td>
                                <td>
                                    <div class="td-content product-brand">{{item?.company_name || '--'}}</div>
                                </td>
                                <td>
                                    <div class="td-content customer-name pimg">{{item?.name || '--'}}</div>
                                </td>
                                <td>
                                    <div class="td-content"><span class="pricing"><i class="fal fa-rupee-sign">
                                                {{item?.mrp || '--'}}</i></span></div>
                                </td>
                                <td> {{item?.ptr || '--'}}</td>
                                <td> {{item?.created_at || '--'}}</td>
                            </tr>


                        </tbody>

                    </table>

                    <div class="tm-action-btn" *ngIf="dashboardData.unmapped !=0">
                        <button class="btn float-right" routerLink="/inventory-mapping/item-inventory">See All</button>
                    </div>
                </div>
            </div>
            <div *ngIf="dashboardData.unmapped == 0">
                <span class="d-block text-center">No record Found!</span>
            </div>
        </div>
    </div>
    <!-- <div class="col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
        <div class="widget widget-chart-three">
            <div class="widget-heading">
                <div class="">
                    <h5 class="">Daily Orders</h5>
                </div>

                <div class="dropdown  custom-dropdown">
                    <a class="dropdown-toggle" href="#" role="button" id="uniqueVisitors" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                            class="feather feather-more-horizontal">
                            <circle cx="12" cy="12" r="1"></circle>
                            <circle cx="19" cy="12" r="1"></circle>
                            <circle cx="5" cy="12" r="1"></circle>
                        </svg>
                    </a>

                </div>
            </div>

            <div class="widget-content">
                <div id="chart">
                    <google-chart [data]="myGraph"></google-chart>

                </div>

            </div>
        </div>
    </div> -->
    <!-- <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 layout-spacing">
        <div class="c-delight-main blue">

            <div class="text-item">12</div>
            <h2 class="c-delight">Items Order Today</h2>
        </div>

        <div class="c-delight-main pink">

            <div class="text-item">1200</div>
            <h2 class="c-delight">Active SKU</h2>
        </div>


        <div class="c-delight-main green">

            <div class="text-item">23</div>
            <h2 class="c-delight">Items total order in shortage</h2>
        </div>


    </div> -->

    <!-- <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
        <div class="widget widget-table-two widget-chart-one">
            <div class="widget-heading">
                <h5 class="">Most active items</h5>
                <ul class="tabs tab-pills">
                    <li><a href="#">3 m</a></li>
                    <li><a href="#">6 m</a></li>
                    <li><a href="#">12 m</a></li>


                </ul>
            </div>
            <div class="widget-content">
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>
                                    <div class="th-content">Username</div>
                                </th>
                                <th>
                                    <div class="th-content">Qty</div>
                                </th>
                                <th>
                                    <div class="th-content">value</div>
                                </th>
                                <th>
                                    <div class="th-content th-heading">Remark</div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <div class="td-content product-brand">ADROIT AGENCIES</div>
                                </td>
                                <td>
                                    <div class="td-content customer-name pimg">4</div>
                                </td>
                                <td>
                                    <div class="td-content"><span class="pricing">$84.00</span></div>
                                </td>
                                <td class="success"><i class="fa fa-circle"></i> Success</td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="td-content product-brand">ADROIT AGENCIES</div>
                                </td>
                                <td>
                                    <div class="td-content customer-name pimg">4</div>
                                </td>
                                <td>
                                    <div class="td-content"><span class="pricing">$84.00</span></div>
                                </td>
                                <td class="wait"><i class="fa fa-circle"></i> Success</td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="td-content product-brand">ADROIT AGENCIES</div>
                                </td>
                                <td>
                                    <div class="td-content customer-name pimg">4</div>
                                </td>
                                <td>
                                    <div class="td-content"><span class="pricing">$84.00</span></div>
                                </td>
                                <td class="failed"><i class="fa fa-circle"></i> Success</td>
                            </tr>


                        </tbody>
                    </table>

                </div>
            </div>
        </div>
    </div> -->
    <!-- <div class="col-xl-4 col-lg-12 col-md-6 col-sm-12 col-12 layout-spacing">
        <div class="widget widget-table-one">
            <div class="widget-heading">
                <h5 class="">Transactions</h5>
            </div>

            <div class="widget-content">
                <div class="transactions-list">
                    <div class="t-item">
                        <div class="t-company-name">
                            <div class="t-icon">
                                <div class="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-home">
                                        <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                                        <polyline points="9 22 9 12 15 12 15 22"></polyline>
                                    </svg>
                                </div>
                            </div>
                            <div class="t-name">
                                <h4>Electricity Bill</h4>
                                <p class="meta-date">4 Aug 1:00PM</p>
                            </div>

                        </div>
                        <div class="t-rate rate-dec">
                            <p><span>-$16.44</span> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-down">
                                    <line x1="12" y1="5" x2="12" y2="19"></line>
                                    <polyline points="19 12 12 19 5 12"></polyline>
                                </svg></p>
                        </div>
                    </div>
                </div>

                <div class="transactions-list">
                    <div class="t-item">
                        <div class="t-company-name">
                            <div class="t-icon">
                                <div class="avatar avatar-xl">
                                    <span class="avatar-title rounded-circle">SP</span>
                                </div>
                            </div>
                            <div class="t-name">
                                <h4>Shaun Park</h4>
                                <p class="meta-date">4 Aug 1:00PM</p>
                            </div>
                        </div>
                        <div class="t-rate rate-inc">
                            <p><span>+$66.44</span> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-up">
                                    <line x1="12" y1="19" x2="12" y2="5"></line>
                                    <polyline points="5 12 12 5 19 12"></polyline>
                                </svg></p>
                        </div>
                    </div>
                </div>

                <div class="transactions-list">
                    <div class="t-item">
                        <div class="t-company-name">
                            <div class="t-icon">
                                <div class="avatar avatar-xl">
                                    <span class="avatar-title rounded-circle">AD</span>
                                </div>
                            </div>
                            <div class="t-name">
                                <h4>Amy Diaz</h4>
                                <p class="meta-date">4 Aug 1:00PM</p>
                            </div>

                        </div>
                        <div class="t-rate rate-inc">
                            <p><span>+$66.44</span> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-up">
                                    <line x1="12" y1="19" x2="12" y2="5"></line>
                                    <polyline points="5 12 12 5 19 12"></polyline>
                                </svg></p>
                        </div>
                    </div>
                </div>

                <div class="transactions-list">
                    <div class="t-item">
                        <div class="t-company-name">
                            <div class="t-icon">
                                <div class="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-home">
                                        <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                                        <polyline points="9 22 9 12 15 12 15 22"></polyline>
                                    </svg>
                                </div>
                            </div>
                            <div class="t-name">
                                <h4>Netflix</h4>
                                <p class="meta-date">4 Aug 1:00PM</p>
                            </div>

                        </div>
                        <div class="t-rate rate-dec">
                            <p><span>-$32.00</span> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-down">
                                    <line x1="12" y1="5" x2="12" y2="19"></line>
                                    <polyline points="19 12 12 19 5 12"></polyline>
                                </svg></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <!-- <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 layout-spacing">
        <div class="widget-three">
            <div class="widget-heading">
                <h5 class="">Summary</h5>
            </div>
            <div class="widget-content">

                <div class="order-summary">

                    <div class="summary-list">
                        <div class="w-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" class="feather feather-shopping-bag">
                                <path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path>
                                <line x1="3" y1="6" x2="21" y2="6"></line>
                                <path d="M16 10a4 4 0 0 1-8 0"></path>
                            </svg>
                        </div>
                        <div class="w-summary-details">

                            <div class="w-summary-info">
                                <h6>Income</h6>
                                <p class="summary-count">$92,600</p>
                            </div>

                            <div class="w-summary-stats">
                                <div class="progress">
                                    <div class="progress-bar bg-gradient-secondary" role="progressbar"
                                        style="width: 90%" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100">
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                    <div class="summary-list">
                        <div class="w-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" class="feather feather-tag">
                                <path
                                    d="M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z">
                                </path>
                                <line x1="7" y1="7" x2="7" y2="7"></line>
                            </svg>
                        </div>
                        <div class="w-summary-details">

                            <div class="w-summary-info">
                                <h6>Profit</h6>
                                <p class="summary-count">$37,515</p>
                            </div>

                            <div class="w-summary-stats">
                                <div class="progress">
                                    <div class="progress-bar bg-gradient-success" role="progressbar" style="width: 65%"
                                        aria-valuenow="65" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>

                        </div>

                    </div>

                    <div class="summary-list">
                        <div class="w-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" class="feather feather-credit-card">
                                <rect x="1" y="4" width="22" height="16" rx="2" ry="2"></rect>
                                <line x1="1" y1="10" x2="23" y2="10"></line>
                            </svg>
                        </div>
                        <div class="w-summary-details">

                            <div class="w-summary-info">
                                <h6>Expenses</h6>
                                <p class="summary-count">$55,085</p>
                            </div>

                            <div class="w-summary-stats">
                                <div class="progress">
                                    <div class="progress-bar bg-gradient-warning" role="progressbar" style="width: 80%"
                                        aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>

            </div>
        </div>
    </div> -->
    <!-- <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 layout-spacing">

        <div class="widget widget-account-invoice-one">

            <div class="widget-heading">
                <h5 class="">Account Info</h5>
            </div>

            <div class="widget-content">
                <div class="invoice-box">

                    <div class="acc-total-info">
                        <h5>Balance</h5>
                        <p class="acc-amount">$470</p>
                    </div>

                    <div class="inv-detail">
                        <div class="info-detail-1">
                            <p>Monthly Plan</p>
                            <p>$ 199.0</p>
                        </div>
                        <div class="info-detail-2">
                            <p>Taxes</p>
                            <p>$ 17.82</p>
                        </div>
                        <div class="info-detail-3 info-sub">
                            <div class="info-detail">
                                <p>Extras this month</p>
                                <p>$ -0.68</p>
                            </div>
                            <div class="info-detail-sub">
                                <p>Netflix Yearly Subscription</p>
                                <p>$ 0</p>
                            </div>
                            <div class="info-detail-sub">
                                <p>Others</p>
                                <p>$ -0.68</p>
                            </div>
                        </div>
                    </div>

                    <div class="inv-action">
                        <a href="" class="btn btn-outline-dark">Summary</a>
                        <a href="" class="btn btn-danger">Transfer</a>
                    </div>
                </div>
            </div>

        </div>
    </div>   -->

</div>









<!--  END CONTENT AREA  -->