import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataTablesModule } from 'angular-datatables';
import { DataTableToolsComponent } from 'src/app/common/data-table-tools/data-table-tools.component';



@NgModule({
  declarations: [
    DataTableToolsComponent
  ],
  imports: [
    CommonModule,
    DataTablesModule
  ],
  exports: [
    DataTableToolsComponent
  ]
})
export class DataTableToolsModule { }
