import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, catchError, Observable, retry, Subject, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2'

@Injectable({
  providedIn: 'root'
})

export class ApiService {
 
  phoneNumber: any

  constructor(private http: HttpClient) { }
  private subject = new Subject<any>();

  sendMessage(message: string) {
      this.subject.next({ text: message });
   
  }

 onMessage(): Observable<any> {
      return this.subject.asObservable();
  }

destroyObservable(){
  if(this.subject){
    this.subject.unsubscribe()
  }
}

// postWithoutLoad(path: string, payload: any = null, isLoading = false): Observable<any> {
//   if (!(payload instanceof FormData) && typeof payload == 'object' && payload != null) {
//     payload = this.objToFormData(payload);
//   }
//   return this.http.post<any>(environment.apiBase + path, payload,{
//     reportProgress: isLoading,
//   })
//     .pipe(
//       retry(0),
//       catchError(this.errorHandler)
//     )
// }

  post(path: string, payload: any = null, isLoading = true): Observable<any> {
    if (!(payload instanceof FormData) && typeof payload == 'object' && payload != null) {
      payload = this.objToFormData(payload);
    }
    return this.http.post<any>(environment.apiBase + path, payload,{
      reportProgress: isLoading,
    })
      .pipe(
        retry(0),
        catchError(this.errorHandler)
      )
  }
  postData(path: string, payload: any = null): Observable<any> {

    return this.http.post<any>(environment.apiBase + path, payload)
      .pipe(
        retry(0),
        catchError(this.errorHandler)
      )
  }

  postToDataTable(path: string, payload: any = null): Observable<any> {

    return this.http.post<any>(path, payload)
      .pipe(
        retry(0),
        catchError(this.errorHandler)
      )
  }

  objToFormData(payload: any) {
    const formData = new FormData();
    // console.log(payload)
    this.formDataRecurrence(formData, payload);
    return formData;
  }

  formDataRecurrence(formData: FormData, payload: any, extendKey: any = []) {
    if (payload instanceof Object) {
      Object.keys(payload).map((key) => {
  
        
        var keys = Object.assign([], extendKey);
        keys.push(key);
        if ((payload[key] instanceof Object) == true && (payload[key] instanceof File) == false) {
          this.formDataRecurrence(formData, payload[key], keys);
        } else {
          if (keys.length > 1) {
            formData.append(this.keysToString(keys), payload[key])
          } else {
            formData.append(key, payload[key])
          }
        }
        keys = [];
      });
    }
  }
  keysToString(array: any):string {
    let key = '';
    let count = 0;
    for (let v of array) {
      if (count == 0) {
        key += v;
      } else {
        key += '[' + v + ']';
      }
      count++;
    }
    return key;
  }
  errorHandler(err: any) {
    let error: any = '';
    if (err.error instanceof ErrorEvent) {
      error = err.error.message;
    } else {
      error = `Error Code: ${err.status}\nMessage: ${err.message}`;
    }
    return throwError(() => error)
  }

  getLocationService(): Promise<any> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resp => {
        resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude })
      })
    })
  }

  success(e: any) {
    Swal.fire({
      title: e.message,
      icon: 'success'
    });
  }

  error(e: any) {
    console.log('error',e)
    if (typeof e === 'string') {
      Swal.fire(
        'Error !',
        e,
        'error'
      )
    } else {
      Swal.fire(
        'Error !',
        e.message,
        'error'
      )
    }

  }

  isConfirmed(callable: any = () => { return true; },
    title: string = 'Are you sure you would like to delete?') {
    Swal.fire({
      title: title,
      showCancelButton: true,
      icon: 'info',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        callable();
      }
    })
  }
resetpasswordAutomatically:any='cancel'
  resetPassword(manualCallable: any, autoCallable: any) {
    Swal.fire({
      title: 'Would you like to reset password?',
      showCancelButton: true,
      icon: 'info',
      confirmButtonText: 'Manually',
      cancelButtonText: 'Automatically',
    }).then((result) => {
      console.log(result?.value,result?.dismiss,result?.isConfirmed,result?.isDenied,result?.isDismissed)
      if (result.isConfirmed) {
        //---//---Manually change password---
        Swal.fire({
          title: 'Enter new password',
          input: 'text',
          inputAttributes: {
            autocapitalize: 'off'
          },
          showCancelButton: true,
          confirmButtonText: 'Submit',
          showLoaderOnConfirm: true,
          preConfirm: (password) => {
            manualCallable(password);
          },
        });
      } else if(result.dismiss==this.resetpasswordAutomatically){
        //---Automatically change password---
        autoCallable();
      }
    })
  }


  Getsessiondata() { 
    if (localStorage.getItem('userInfo')) {
      let encode: any = localStorage.getItem('userInfo');
      let  sesion_data = JSON.parse(encode);
      const jsondata = sesion_data;
      return jsondata;
    } else {
      return false;
    }
  }

}
