import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { LoaderService } from 'src/app/loader.service';
import { ApiService } from 'src/app/service/api.service';

@Component({
  selector: 'app-auto-complete',
  templateUrl: './auto-complete.component.html',
  styleUrls: ['./auto-complete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutoCompleteComponent),
      multi: true,
    },
  ],
})
export class AutoCompleteComponent implements ControlValueAccessor {
  @Input('placeholder')
  placeholder: string = 'Please select the value....';

  @Input('url')
  url: any = null;

  @Input('data')
  data = [];

  @Input('apiObject')
  apiObject: any = { q: null };

  @Input()
  isDisabled: boolean = false;

  @Input('keyword')
  keyword: string = 'name';

  @Input('history')
  history: string = '';

  @Input('clear')
  clear: any = '';

  isLoading: boolean = false;
  debounceTime = 0;

  input: any = { id: null, name: null };
  item: any = { id: null, name: null };

  @Output() openModalFunc = new EventEmitter();
  @Output() addDeleted = new EventEmitter();

  constructor(private api: ApiService, private loader: LoaderService) {}

  openModal(item: any) {
    this.item = item;
    this.openModalFunc.emit(item);
  }

  ngOnInit(): void {
    if (this.url) {
      this.debounceTime = 200;
    }
  }

  ngOnDestroy() {}

  /*Autocomplete Functions */
  selectEvent(item: any) {
    console.log('selectEvent -->', item);
    this.item = item;

    this.onChange(item);
  }

  onFocused(e: any) {
    // console.log(e);
  }

  clearSearch() {
    // this.item = { id: null, name: null };
    // this.onChange(this.item);
    console.log('deletion', this.item);
    this.addDeleted.emit(this.item);
  }
  onChangeSearch(val: string) {
    if (this.url) {
      this.apiObject.q = val;
      this.api.post(this.url, this.apiObject, false).subscribe((res) => {
        if (res.code == 200) {
          this.data = res.data;
        }
      });
    }
  }
  /*ControlValueAccessor Functions */

  onChange: any = (item: any) => {
    console.log(item, 'i was deleted');
  };
  onTouch: any = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(input: any) {
    console.log('writeValue -->', input);

    this.item = input;
    this.input = this.item;
  }
}
