
 <div id="progressBarAnimated" class="col-lg-12 layout-spacing">
   <div class="statbox widget box box-shadow">
     
       <div class="widget-content widget-content-area">
         
           <div class="progress br-30">
               <div class="progress-bar bg-primary progress-bar-striped progress-bar-animated" role="progressbar" style="width: 20%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
           </div>
       </div>
   </div>
</div>