import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Select2Component } from 'src/app/common/select2/select2.component';
import { FormsModule } from '@angular/forms';
import { NgSelect2Module } from 'ng-select2';



@NgModule({
  declarations: [
    Select2Component
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgSelect2Module
  ],
  exports: [
    Select2Component
  ]
})
export class Select2Module { }
