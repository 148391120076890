import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { StoreService } from './store.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private requests: any = { }; 
 
  constructor(private store: StoreService,private route:Router) { }

  getAuthStatus(): boolean {
    return this.store.has('authInfo');
  }
  getAuthToken(): any {
    const authInfo = this.store.get('authInfo');
    return authInfo.type + ' ' + authInfo.token;
  }
  getPermissions(): any {
    return this.store.get('permission');
  }
  logout() {
    localStorage.removeItem('authInfo');
    localStorage.clear();
    this.route.navigate(['/login']);
  }
 
}

  /*----------------------------------------------- caching method---------------------------------------------------- */
//   put(url: string, response: HttpResponse<any>): void {  
//     this.requests[url] = response;  
//   }  
  
//   get(url: string): HttpResponse<any> | undefined {  
//     return this.requests[url];  
//   }  
  
//   invalidateCache(): void {  
//     this.requests = { };  
//   } 
// }
