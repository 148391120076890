export var config = {
  auth: {
    login: '/auth/login',
    otp: '/auth/verify',
    checkSession: '/auth/isLogin',
    changePassword: '/change-password',
    forget: {
      verifyUser: '/reset-password-tokenweb',
      changePassword: '/reset-passwordweb',
    },
  },
  registration: {
    sendOtp: '/buyer/registration/send',
    verify: '/buyer/registration/verify',
    step1: '/buyer/registration/step1',
    step2: '/buyer/registration/step2',
    step3: '/buyer/registration/step3',
    step4: '/buyer/registration/step4',
  },
  item: {
    create: '/item/create',
    update: '/item/update',
    read: '/item/read',
    delete: '/item/delete',
    dataTable: '/item/list',
    uploadCSV: {
      sampleCSV: '/item/upload/sample',
      upload: '/item/upload',
      dataTable: '/item/upload/tasklist',
    },
    autocomplete: {
      parent: '/autocomplete/item',
      color: '/autocomplete/color',
      units: '/autocomplete/unit',
      company: '/autocomplete/company',
      drug: '/autocomplete/drug',
      form: '/autocomplete/form',
      pack: '/autocomplete/pack',
      brand: '/autocomplete/brand',
      salt: '/autocomplete/salt-variant',
      tags: '/autocomplete/tag',
    },
    dropdown: {
      businessunit: '/dropdown/business-unit',
      therapeutic: '/autocomplete/therapeutic-usage',
    },
    ummappedItem: {
      dataTable: '/allunmapped-list',
      addUnmappedItem: '/add-unmapped-item',
    },
  },

  user: {
    admin: {
      dataTable: '/user/admin/list',
      create: '/user/admin/create',
      update: '/user/admin/update',
      get: '/user/admin/read',
      delete: '/user/admin/delete',
      passReset: '/user/admin/reset-password',
      getPermission: '/user/admin/permission/get',
      setPermission: '/user/admin/permission/set',
    },
    buyer: {
      create: {
        step1: '/user/buyer/create/step1',
        step2: '/user/buyer/create/step2',
        step3: '/user/buyer/create/step3',
        step4: '/user/buyer/create/step4',
      },
      update: {
        step1: '/user/buyer/update/step1',
        step2: '/user/buyer/update/step2',
        step3: '/user/buyer/update/step3',
        step4: '/user/buyer/update/step4',
      },
      dataTabel: '/user/buyer/list',
      delete: '/user/buyer/delete',
      Permissions: '/user/buyer/permission/get',
      setPermission: '/user/buyer/permission/set',
      get: '/user/buyer/read',
      passReset: '/user/buyer/reset-password',
      underProccess: '/user/buyer/under-approval/list',
      underProccessRead: '/user/buyer/under-approval/read',
      details: '/user/buyer/details',
    },
    seller: {
      create: {
        step1: '/user/seller/create/step1',
        step2: '/user/seller/create/step2',
        step3: '/user/seller/create/step3',
        step4: '/user/seller/create/step4',
        step7: '/user/seller/create/step7',
        step6: '/user/seller/create/step6',
      },
      update: {
        step1: '/user/seller/update/step1',
        step2: '/user/seller/update/step2',
        step3: '/user/seller/update/step3',
        step4: '/user/seller/update/step4',
        step7: '/user/seller/update/step7',
        step6: '/user/seller/update/step6',
      },
      dataTabel: '/user/seller/list',
      delete: '/user/seller/delete',
      Permissions: '/user/seller/permission/get',
      setPermission: '/user/seller/permission/set',
      get: '/user/seller/read',
      passReset: '/user/seller/reset-password',
      underProccess: '/user/seller/under-approval/list',
      underProccessRead: '/user/seller/under-approval/read',
      details: '/user/seller/details',
    },
    areaMapping: {
      pincodeMapping: '/mapping/pincode',
      areaMapping: '/area-mapping',
      userMapping: '/mapping/user',
    },
    UserNotFound: {
      buyerUserList: '/admin/userlistbytype',
      missingItemMaster: '/admin/missingmasteritemdata',
    },
  },
  company: {
    companymaster: {
      dataTable: '/companymasterlist',
      create: '/company/create',
      update: '/company/update',
      get: '/company/read',
      delete: '/company/delete',
    },
  },
  product: {
    master: {
      category: '/category',
      dataTable: '/category/dataTable',
      create: '/category/create',
      update: '/category/update',
      get: '/category/read',
      delete: '/category/delete',
    },
  },
  onboard: {
    get: '/user/onboard/read',
    reject: '/user/onboard/reject',
    approve: '/user/onboard/approve',
    dataTable: '/user/onboard/list',
    update: {
      step1: '/user/onboard/approve/step1',
      step2: '/user/onboard/approve/step2',
      step3: '/user/onboard/approve/step3',
      step4: '/user/onboard/approve/step4',
      step5: '/user/onboard/approve/step4',
      step6: '/user/onboard/approve/step6',
      step7: '/user/onboard/approve/step7',
    },
  },
  categories: {
    businessUnit: {
      dataTable: '/businessunitsmasterlist',
      delete: '/category/business-unit/delete',
      read: '/category/business-unit/read',
      create: '/category/business-unit/create',
      update: '/category/business-unit/update',
    },
    brandMaster: {
      dataTable: '/brandmasterlist',
      delete: '/category/brand/delete',
      read: '/category/brand/read',
      create: '/category/brand/create',
      update: '/category/brand/update',
    },
    tagsMaster: {
      dataTable: '/tagsmasterlist',
      delete: '/category/tag/delete',
      read: '/category/tag/read',
      create: '/category/tag/create',
      update: '/category/tag/update',
    },
    drugs: {
      dataTable: '/drugmasterlist',
      delete: '/category/drug/delete',
      read: '/category/drug/read',
      create: '/category/drug/create',
      update: '/category/drug/update',
    },
    color: {
      dataTable: '/colormasterlist',
      delete: '/category/color/delete',
      read: '/category/color/read',
      create: '/category/color/create',
      update: '/category/color/update',
    },
    formMaster: {
      dataTable: '/formmasterlist',
      delete: '/category/form/delete',
      read: '/category/form/read',
      create: '/category/form/create',
      update: '/category/form/update',
    },
    packMaster: {
      dataTable: '/packmasterlist',
      delete: '/category/pack/delete',
      read: '/category/pack/read',
      create: '/category/pack/create',
      update: '/category/pack/update',
    },
    therapeutic: {
      dataTable: '/therapeuticmasterlist',
      delete: '/category/therapeutic-usage/delete',
      read: '/category/therapeutic-usage/read',
      create: '/category/therapeutic-usage/create',
      update: '/category/therapeutic-usage/update',
    },
    unit: {
      dataTable: '/unitsmasterlist',
      delete: '/category/unit/delete',
      read: '/category/unit/read',
      create: '/category/unit/create',
      update: '/category/unit/update',
    },
    salt: {
      dataTable: '/saltmasterlist',
      delete: '/category/salt/delete',
      read: '/category/salt/read',
      create: '/category/salt/create',
      update: '/category/salt/update',
    },
    saltVarient: {
      dataTable: '/saltvarientmasterlist',
      delete: '/category/salt-variant/delete',
      read: '/category/salt-variant/read',
      create: '/category/salt-variant/create',
      update: '/category/salt-variant/update',
    },
  },
  autocomplete: {
    pincodeInfo: '/autocomplete/pincode',
    state: '/dropdown/state',
    city: '/autocomplete/city',
    salt: '/autocomplete/salt',
    unit: '/autocomplete/unit',
  },
  buyer: {
    itemMaster: {
      create: '/item/buyer/create',
      update: '/item/buyer/update',
      dataTablebuyer: '/item/buyer/list',
      softDelete: '/item/buyer/delete',
      read: '/item/buyer/read',
      uploadCSV: {
        dataTable: '/item/buyer/upload/tasklist',
        uploadSampleCSV: '/item/buyer/upload/sample',
        submitCSV: '/item/buyer/upload',
      },
      mapdrug: '/itemmap',
      adminMapDrug: '/admin/itemmappdrug',
      repoted: '/rejectitemmap',
      adminReported: '/admin/rejectitemmap',
    },
    placeOrder: {
      buyeritemList: '/item-list-byuser',
      searchItemList: '/item-list-bysearch',
    },

    reOrderSell: {
      reOrderSell: '/reorder/reorderlist',
      listAddToShortBookApi: '/add-shortbook',
      listAddToCart: '/add-cart',
    },
  },
  seller: {
    itemMaster: {
      create: '/item/seller/create',
      update: '/item/seller/update',
      dataTable: '/item/seller/list',
      softDelete: '/item/seller/delete',
      read: '/item/seller/read',
      mapdrug: '/seller/itemmap',
      uploadCSV: {
        dataTable: '/item/seller/upload/tasklist',
        uploadSampleCSV: '/item/seller/upload/sample',
        submitCSV: '/item/seller/upload',
      },
    },
  },
  shortBook: {
    searchByItem: '/elastic/item',
    addToCart: '/add-cart',
    deleteshortbookdata: '/deleteallshortbook',
    cartList: '/cart-list',
    addShortBook: '/add-shortbook',
    shortBookList: '/shortbook-list',
    uploadCsv: '/bulkorder',
    adminUploadCsv: '/admin/bulkorder',
    placeorder: '/placeorder',
    updateshortbookqty: '/updateshortbookqty',
    deletecart: '/deletecart',
    orderdetails: '/orderlist',
    invoicedetails: '/invoicelist',
    primarytosecondaryorder: '/primarytosecondaryorder',
    secondaryorder: '/secondaryorder',
    orderitem: '/orderitem',
    invoiceitem: '/invoiceitemlist',
    secondaryorderItem: '/secondaryorderitem',
    deleteshortbook: '/deleteshortbook',
    cardqty: '/updatecartqty',
    checkstockbyitemweb: '/checkstockbyitemweb',
  },
  MappingInventory: {
    uploadCSV: {
      dataTable: '/inventory/upload/tasklist',
      uploadSampleCSV: '/inventory/upload/sample',
      submitCSV: '/inventory/upload',
    },
    itemMapped: {
      admin: {
        adminUnmappedItem: '/admin/unmappedlist',
        adminmappedItem: '/admin/mappedlist',
        adminReportedItem: '/admin/reportedlist',
      },
      user: {
        unmappedItem: '/unmapped-list',
        mappedItem: '/mapped-list',
        reportItem: '/reported-list',
      },
    },
  },
  profile: {
    getUserData: '/profile/userdata',
    sellerStatus: '/profile/deactiveseller',
  },
  placeOrder: {
    orderList: '/seller/orderlist',
  },
  prefrence: {
    create: '/preference/create',
    dataTable: '/preference/list',
    read: '/preference/read',
    update: '/preference/update',
    buyerlist: '/list/buyer',
    sellerlist: '/list/seller',
    prefrenceType: '/preference/get-type',
  },
  dashboard: '/dashboard',
  elasticSearch: '/elastic/item',

  itemmasterlist: '/itemmasterlist',
  APPLICATION_TOKEN:
    'CNE5tJZAEuxnJ4Gwsm6GKz59v7kj49r9X7xV2D5JeQ3K2QB9jZZqYqDLqeWXfn4z',
  secretKey: '123456$#@$^@1ERF',
  aesEnc: {
    key: '6fa979f20126cb08aa645a8f495f6d85',
    iv: 'I8zyA4lVhMCaJ5Kg',
  },

  cms: {
    getCms: '/admin/cms',
    updateCms: '/admin/updatecms',
  },
  offers: {
    createOffer: '/offer/create',
    updateOffer: '/offer/update',
    offerList: '/offer/list',
    offerRead: '/offer/read',
    offerDelete: '/offer/delete',
    itemList: '/list/item',
    comapnyList: '/list/company',
  },
};
